import { EnumDescriptor } from "../enum_descriptor";

export enum QuantityCalculationModeEnum {
    Undefined = 0,
    QuantityUnit = 1,
    RangeValue = 2,
    PositionsCount = 3
}

export const QuantityCalculationModeValues: Array<
    EnumDescriptor<QuantityCalculationModeEnum>
> = [
        { value: QuantityCalculationModeEnum.Undefined, text: "nicht definiert" },
        { value: QuantityCalculationModeEnum.QuantityUnit, text: "nach Mengeneinheit" },
        { value: QuantityCalculationModeEnum.RangeValue, text: "anhand Rangewert" },
        { value: QuantityCalculationModeEnum.PositionsCount, text: "anhand Positionen" },
    ];